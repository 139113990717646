import React from 'react';
import '../styles/Header.scss';

const Header: React.FC = () => {
  return (
    <header>
      <div className="menu">
        <a className="menuItem" href="/#readBook">Read a book</a>
        <a className="menuItem" href="/about">About YouRead</a>
        <a className="menuItem" href="/news">What's new?</a>
      </div>
    </header>
  );
}

export default Header;
