// HomePage.tsx
import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import '../styles/App.scss';
import EpubViewer from './EpubViewer';
import { Helmet } from 'react-helmet';

const HomePage: React.FC = () => {
  const [showEpubViewer, setShowEpubViewer] = useState(false);
  const [epubFile, setEpubFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type === 'application/epub+zip') {
      setEpubFile(file);
      setIsLoading(true);
      setTimeout(() => {
        setShowEpubViewer(true);
        setIsLoading(false);
      }, 1000); // Simulate loading for 1 second
    } else {
      alert('Please upload a valid ePub file.');
    }
  };


  useEffect(() => {
    if (showEpubViewer) {
      const viewerElement = document.getElementById('viewer');
      viewerElement?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showEpubViewer]);

  return (
    <div className="app">
      <Header />
      <Helmet>
        <meta name="description" content="Read your .epub book anywhere for free!" />
        <link rel="canonical" href="https://youread.net" />
      </Helmet>
      <main className="main">
        <div className="container">
          <h1 className="headline" id="readBook" >YouRead</h1>
          <h2>Read your .epub book anywhere for free!</h2>
          <p>Choose your .epub file:</p>
          <input type="file" id="fileInput" accept=".epub" onChange={handleFileUpload} /><br />
          <label htmlFor="fileInput" className="uploadBtn">Read e-book</label>
          {isLoading && <div className="loader"></div>}
        </div>
      </main>
      {showEpubViewer && epubFile && <EpubViewer epubFile={epubFile} onClose={() => setShowEpubViewer(false)} />}
      <Footer />
    </div>
  );
}

export default HomePage;
