// App.tsx
import React from 'react';
import ReactDOM from 'react-dom';
import AppRouter from './AppRouter';

const App = () => {
  return (
    <React.StrictMode>
      <AppRouter />
    </React.StrictMode>
  );
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

export default App;
