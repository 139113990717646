import React, { useEffect, useState } from 'react';
import ePub, { Book, Rendition } from 'epubjs';

interface EpubViewerProps {
  epubFile: File;
  onClose: () => void;
  savedLocation?: { start: { cfi: string } };
}

const EpubViewer: React.FC<EpubViewerProps> = ({ epubFile, onClose, savedLocation }) => {
  const [book, setBook] = useState<Book | null>(null);
  const [rendition, setRendition] = useState<Rendition | null>(null);
  const [currentPage, setCurrentPage] = useState<number | null>(null);


  useEffect(() => {
    const loadBook = async () => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        if (event.target?.result) {
          const bookData = event.target.result as ArrayBuffer;
          const epubBook = ePub(bookData);
          await epubBook.opened;
          setBook(epubBook);
        }
      };
      reader.readAsArrayBuffer(epubFile);
    };

    if (epubFile) {
      loadBook();
    }

    return () => {
      if (book) {
        book.destroy();
      }
    };
  }, [epubFile]);

  useEffect(() => {
    const initializeBook = async () => {
      if (!book) return; // Pokud kniha není k dispozici, nic nedělejte
  
      const viewer = document.getElementById('viewer');
      if (!viewer) return; // Pokud zobrazovač není k dispozici, nic nedělejte
  
      const rend = book.renderTo(viewer, {
        width: '100%',
        height: '600px',
        allowScriptedContent: true, // Povolení spouštění skriptů
      });

      rend.display();
  
      rend.on('book:ready', () => {
        if (savedLocation && savedLocation.start) {
          rend.display(savedLocation.start.cfi);
        } else {
          rend.display(0);
        }
      });
  
      rend.on('relocated', async (location: { start: { displayed?: { page: number } } }) => {
        if (location.start.displayed) {
          const currentPage = location.start.displayed.page;
          setCurrentPage(currentPage);
          document.cookie = `currentPage=${currentPage}; expires=Thu, 01 Jan 2025 00:00:00 UTC; path=/`;
        } else {
          await new Promise(resolve => setTimeout(resolve, 500));
          rend.display();
        }
      });

      setRendition(rend);
    };
  
    const checkBookReady = async () => {
      if (!book) return; // Pokud kniha není k dispozici, nic nedělejte
  
      const isBookReady = await book.opened;
      if (!isBookReady) {
        // Pokud kniha není plně načtena, počkejte a zopakujte kontrolu
        setTimeout(checkBookReady, 1000); // Zopakujte po 1 sekundě
      } else {
        // Pokud je kniha plně načtena, proveďte inicializaci
        initializeBook();
      }
    };
  
    checkBookReady();
  
    return () => {
      if (book) {
        book.destroy();
      }
    };
  }, [book, savedLocation]);
  

  
  

  const handleCloseBook = () => {
    const confirmed = window.confirm('Are you sure you want to close the book?');
    if (confirmed) {
      onClose();
      window.location.reload();
    }
  };

  return (
    <div>
      <h2>{epubFile.name.replace(/\.[^/.]+$/, '')}</h2>
      <div id="viewer" style={{ width: '100%', height: '600px' }}></div>
      <div className="navButtons">
        <button className="btnPrev" onClick={() => rendition?.prev()}>Previous</button>
        <button className="btnNext" onClick={() => rendition?.next()}>Next</button><br />
        <button className="btnClose" onClick={handleCloseBook}>Close book</button>
      </div>
      <div>
        Current page: <span style={{ fontStyle: 'italic' }}>{currentPage !== null ? currentPage : 'N/A'}</span>
      </div>
    </div>
  );
};

export default EpubViewer;
