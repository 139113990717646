import React from 'react';
import Header from './Header';
import Footer from './Footer';
import '../styles/About.scss';
import { Helmet } from 'react-helmet';

const AboutPage: React.FC = () => {
  return (
    <div className="app">
      <Helmet>
        <title>About YouRead - Free online e-pub reader</title>
        <meta
          name="description"
          content="Learn more about YouRead, an online e-book reader that allows users to read ePub books directly from their web browser."
        />
        <link rel="canonical" href="https://youread.net/about" />
      </Helmet>
      <Header />
      <main className="main">
        <div className="container">
          <h2>What is YouRead?</h2>
          <p className='paragraph'>Our application serves as an online e-book reader accessible directly from your web browser. It offers users the convenience of reading electronic books in the ePub format without the need to download additional software. Completely free to use, our platform provides a seamless reading experience, allowing readers to access their favorite books with ease and flexibility. Dive into your digital library and enjoy a vast collection of e-books anytime, anywhere.</p>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default AboutPage;
