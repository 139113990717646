import React from 'react';
import Header from './Header';
import Footer from './Footer';
import '../styles/New.scss';
import { Helmet } from 'react-helmet';

const News: React.FC = () => {
  return (
    <div className="app">
      <Header />
      <Helmet>
        <title>What's New in YouRead - free online e-pub reader!</title>
        <meta
          name="description"
          content="Stay up to date with the latest features, improvements, and bug fixes. YouRead is a free online ePub reader that allows users to read ePub books directly from their web browser."
        />
        <link rel="canonical" href="https://youread.net/news" />
      </Helmet>
      <main className="main">
        <div className="container">
          <h2>What's new?</h2>
          <p className='paragraph'>Stay up to date with the latest features, improvements, and bug fixes in YouRead.</p>
          <h3>v1.0</h3>
          <ul>
            <li>Added support for reading ePub files directly in the browser.</li>
            <li>Improved performance and stability.</li>
            <li>Fixed various bugs and issues reported by users.</li>
          </ul>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default News;
